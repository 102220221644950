import { useMutation } from 'react-query';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { API_BASE_URL, FUNCTION_KEY, PROJECT_SCOPE, IMAGES_URL } from '../../config';
import { useAuthenticatedQuery } from '../useAuthenticatedQuery';
import { BlobServiceClient } from '@azure/storage-blob';
import logError from '../metadata/ErrorLogging';

const useUploadImage = (onSavePath) => {
    const [uploadProgress, setUploadProgress] = useState(0);

    const mutation = useMutation(async ({ file, sasToken, type, id }) => {
        try {
            const fileExtension = file.type?.split('/').pop();
            if (!fileExtension) {
                throw new Error('Invalid file type. Unable to determine file extension.');
            }
    
            const filename = `${type}_${id}.${fileExtension}`;
            const containerUrl = `${IMAGES_URL.replace(/\/$/, '')}?${sasToken}`;
    
            const blobServiceClient = new BlobServiceClient(containerUrl);
            const containerClient = blobServiceClient.getContainerClient('');
            const blobClient = containerClient.getBlockBlobClient(filename);
    
            await blobClient.uploadData(file, {
                onProgress: (progress) => {
                    setUploadProgress((progress.loadedBytes / file.size) * 100); // Update progress
                },
                blobHTTPHeaders: { blobContentType: file.type }, // Set content type
            });
    
            return filename;
        } catch (error) {
            logError('Error in upload mutation', error, {});
            throw error; // Let React Query handle the error
        }
    }, {
        onError: (error) => logError('Error uploading image', error, {}),
        onSuccess: (filename) => {
            
            // Avoid caching on newly uploaded images where filename is unchanged
            const filenameWithTimestamp = `${filename}?${Date.now()}`;

            // Call the onSavePath function with the updated filename
            onSavePath(filenameWithTimestamp); 
        }
    });

    return { ...mutation, uploadProgress };
};

const useSasToken = (returnType = 'read') => {

    if (!["read","upload"].includes(returnType)) {
        throw new Error('Invalid token type. Use "read" or "upload"');
    }

    const [triggerFetch, setTriggerFetch] = useState(false);

    //Expiry times in seconds, set to match the image_token API settings
    const EXPIRY_TIMES = {
        read: 3600, // 1 hour
        upload: 300, // 5 minutes
    };

    const fetchSasToken = async (token) => {
        const url = `${API_BASE_URL}disqoadmin/image_token?returnType=${returnType}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-functions-key': FUNCTION_KEY
            }
        });
        if (!response.ok) {
            logError('Failed to fetch SAS token', response, {});
            throw new Error(`Failed to fetch SAS token, status ${response.status}`);
        }
        
        const data = await response.json();
        return data.sasToken;
    };

    const projectScope = [PROJECT_SCOPE];
    const { data: sasToken, isLoading, error } = useAuthenticatedQuery(
        ['sasToken', returnType], //Use the return type in the query key to separate read and upload tokens in the cache 
        fetchSasToken, 
        projectScope,
        {
            enabled: triggerFetch,  // Triggered manually
            onSuccess: () => setTriggerFetch(false),  // Reset trigger after successful fetch
            staleTime: (EXPIRY_TIMES[returnType] * 0.8) * 1000,  // Set time before refresh to 80% of expiry
            cacheTime: EXPIRY_TIMES[returnType] * 1000,  // Set usable token time to expiry
        }
    );

    const triggerFetchToken = () => {
        setTriggerFetch(true);
    };

    return { sasToken, isLoading, error, triggerFetchToken };
};

export { useUploadImage, useSasToken };

useUploadImage.propTypes = {
    onSavePath: PropTypes.func
};